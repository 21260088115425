<!-- 监管详情 -->

<template>
	<div>
		<w-dialog ref="refWdialog" class="add-dialog" :modalClick="false" title="详情" width="80%" btnWidth="140px"
			top="5vh" :hideFooter="true">
			<div class="stand-title">{{ initData.s_name }}</div>
			<div class="supervise-content">
				<el-row>
					<el-col :span="8" class="flex">
						<div class="bg-title">监管平台互联互通</div>
						<el-tooltip class="box-item" effect="light" :content="initData.osp_name" placement="bottom">
							<div class="contents">{{ initData.osp_name }}</div>
						</el-tooltip>
					</el-col>
					<el-col :span="8" class="flex">
						<div class="bg-title">唯一编码</div>
						<el-tooltip class="box-item" effect="light" :content="initData.s_no" placement="bottom">
							<div class="contents">{{ initData.s_no }}</div>
						</el-tooltip>
					</el-col>
					<el-col :span="8" class="flex">
						<div class="bg-title">平台运营商ID</div>
						<el-tooltip class="box-item" effect="light" :content="initData.o_name" placement="bottom">
							<div class="contents">{{ initData.o_name }}</div>
						</el-tooltip>
					</el-col>
					<el-col :span="8" class="flex">
						<div class="bg-title">国家代码</div>
						<el-tooltip class="box-item" effect="light" :content="initData.s_country_code"
							placement="bottom">
							<div class="contents">{{ initData.s_country_code }}</div>
						</el-tooltip>
					</el-col>
					<el-col :span="8" class="flex">
						<div class="bg-title">省市辖区编码</div>
						<el-tooltip class="box-item" effect="light" :content="initData.sbi_area_code_txt"
							placement="bottom">
							<div class="contents">{{ initData.sbi_area_code_txt }}</div>
						</el-tooltip>
					</el-col>
					<el-col :span="8" class="flex">
						<div class="bg-title">行政区县</div>
						<el-tooltip class="box-item" effect="light" :content="initData.sbi_area_code_countryside_txt"
							placement="bottom">
							<div class="contents">{{ initData.sbi_area_code_countryside_txt }}</div>
						</el-tooltip>
					</el-col>
					<el-col :span="8" class="flex">
						<div class="bg-title">详细地址</div>
						<el-tooltip class="box-item" effect="light" :content="initData.sbi_address" placement="bottom">
							<div class="contents">{{ initData.sbi_address }}</div>
						</el-tooltip>
					</el-col>
					<el-col :span="8" class="flex">
						<div class="bg-title">站点电话</div>
						<el-tooltip class="box-item" effect="light" :content="initData.sbi_station_tel"
							placement="bottom">
							<div class="contents">{{ initData.sbi_station_tel }}</div>
						</el-tooltip>
					</el-col>
					<el-col :span="8" class="flex">
						<div class="bg-title">服务电话</div>
						<el-tooltip class="box-item" effect="light" :content="initData.sbi_service_tel"
							placement="bottom">
							<div class="contents">{{ initData.sbi_service_tel }}</div>
						</el-tooltip>
					</el-col>
					<el-col :span="8" class="flex">
						<div class="bg-title">站点分类</div>
						<el-tooltip class="box-item" effect="light" :content="initData.sbi_station_classification_txt"
							placement="bottom">
							<div class="contents">{{ initData.sbi_station_classification_txt }}</div>
						</el-tooltip>
					</el-col>
					<el-col :span="8" class="flex">
						<div class="bg-title">通用类型</div>
						<el-tooltip class="box-item" effect="light" :content="initData.sbi_general_application_type_txt"
							placement="bottom">
							<div class="contents">{{ initData.sbi_general_application_type_txt }}</div>
						</el-tooltip>
					</el-col>
					<el-col :span="8" class="flex">
						<div class="bg-title">站点类型</div>
						<el-tooltip class="box-item" effect="light" :content="initData.station_type_txt"
							placement="bottom">
							<div class="contents">{{ initData.station_type_txt }}</div>
						</el-tooltip>
					</el-col>
					<el-col :span="8" class="flex">
						<div class="bg-title">站点状态</div>
						<el-tooltip class="box-item" effect="light" :content="initData.s_status_txt" placement="bottom">
							<div class="contents">{{ initData.s_status_txt }}</div>
						</el-tooltip>
					</el-col>
					<el-col :span="8" class="flex">
						<div class="bg-title">车位数量</div>
						<div class="contents">{{ initData.sbi_park_nums }}</div>
					</el-col>
					<el-col :span="8" class="flex">
						<div class="bg-title">经度</div>
						<div class="contents">{{ initData.sbi_lng }}</div>
					</el-col>
					<el-col :span="8" class="flex">
						<div class="bg-title">纬度</div>
						<div class="contents">{{ initData.sbi_lat }}</div>
					</el-col>
					<el-col :span="8" class="flex">
						<div class="bg-title">站点引导</div>
						<el-tooltip class="box-item" effect="light" :content="initData.sbi_site_guide"
							placement="bottom">
							<div class="contents">{{ initData.sbi_site_guide }}</div>
						</el-tooltip>
					</el-col>
					<el-col :span="8" class="flex">
						<div class="bg-title">建设场所</div>
						<el-tooltip class="box-item" effect="light" :content="initData.station_contruction_txt"
							placement="bottom">
							<div class="contents">{{ initData.station_contruction_txt }}</div>
						</el-tooltip>
					</el-col>
					<el-col :span="8" class="flex">
						<div class="bg-title">营业时间</div>
						<el-tooltip class="box-item" effect="light" :content="initData.sbi_busine_hours"
							placement="bottom">
							<div class="contents">{{ initData.sbi_busine_hours }}</div>
						</el-tooltip>
					</el-col>
					<el-col :span="8" class="flex">
						<div class="bg-title">7*24小时营业</div>
						<div class="contents">{{ initData.sbi_round_the_clock == '0' ? '否' : '是' }}
						</div>
					</el-col>
					<el-col :span="8" class="flex">
						<div class="bg-title">停车费类型</div>
						<el-tooltip class="box-item" effect="light" :content="initData.sbi_park_type_txt"
							placement="bottom">
							<div class="contents">{{ initData.sbi_park_type_txt }}</div>
						</el-tooltip>
					</el-col>
					<el-col :span="8" class="flex">
						<div class="bg-title">停车费描述</div>
						<el-tooltip class="box-item" effect="light" :content="initData.sbi_park_fee" placement="bottom">
							<div class="contents">{{ initData.sbi_park_fee }}</div>
						</el-tooltip>
					</el-col>
					<el-col :span="8" class="flex">
						<div class="bg-title">支付方式</div>
						<el-tooltip class="box-item" effect="light" :content="initData.sbi_payment" placement="bottom">
							<div class="contents">{{ initData.sbi_payment }}</div>
						</el-tooltip>
					</el-col>
					<el-col :span="8" class="flex">
						<div class="bg-title">是否支持预约</div>
						<div class="contents">{{ initData.sbi_support_order_txt }}
						</div>
					</el-col>
					<el-col :span="8" class="flex">
						<div class="bg-title">备注</div>
						<el-tooltip class="box-item" effect="light" :content="initData.sbi_remark" placement="bottom">
							<div class="contents">{{ initData.sbi_remark }}</div>
						</el-tooltip>
					</el-col>
					<el-col :span="8" class="flex">
						<div class="bg-title">电费类型</div>
						<el-tooltip class="box-item" effect="light" :content="initData.sbi_electricity_type_txt"
							placement="bottom">
							<div class="contents">{{ initData.sbi_electricity_type_txt }}</div>
						</el-tooltip>
					</el-col>
					<el-col :span="8" class="flex">
						<div class="bg-title">报装类型</div>
						<div class="contents">{{ initData.sbi_business_expand_type == '0' ? '否' : '是' }}
						</div>
					</el-col>
					<el-col :span="8" class="flex">
						<div class="bg-title">报装电源容量</div>
						<el-tooltip class="box-item" effect="light" :content="initData.sbi_capacity" placement="bottom">
							<div class="contents">{{ initData.sbi_capacity }}</div>
						</el-tooltip>
					</el-col>
					<el-col :span="8" class="flex">
						<div class="bg-title">站点额定总功率</div>
						<el-tooltip class="box-item" effect="light" :content="initData.sbi_rated_power"
							placement="bottom">
							<div class="contents">{{ initData.sbi_rated_power }}KW</div>
						</el-tooltip>
					</el-col>
					<el-col :span="8" class="flex">
						<div class="bg-title">峰谷分时</div>
						<div class="contents">{{ initData.sbi_period_fee == '0' ? '无' : '有' }}</div>
					</el-col>
					<el-col :span="8" class="flex">
						<div class="bg-title">正式投运时间</div>
						<el-tooltip class="box-item" effect="light" :content="initData.sbi_official_runtime"
							placement="bottom">
							<div class="contents">{{ initData.sbi_official_runtime }}</div>
						</el-tooltip>
					</el-col>
					<el-col :span="8" class="flex">
						<div class="bg-title">充换电方位</div>
						<el-tooltip class="box-item" effect="light" :content="initData.sbi_station_orientation_txt"
							placement="bottom">
							<div class="contents">{{ initData.sbi_station_orientation_txt }}</div>
						</el-tooltip>
					</el-col>
					<el-col :span="8" class="flex">
						<div class="bg-title">充换电站建筑面积</div>
						<el-tooltip class="box-item" effect="light" :content="initData.sbi_station_area"
							placement="bottom">
							<div class="contents">{{ initData.sbi_station_area }}</div>
						</el-tooltip>
					</el-col>
					<el-col :span="8" class="flex">
						<div class="bg-title">充换电站人工值守</div>
						<div class="contents">{{ initData.sbi_have_person == '0' ? '无' : '有' }}</div>
					</el-col>
					<el-col :span="8" class="flex">
						<div class="bg-title">视频监控配套情况</div>
						<div class="contents">{{ initData.sbi_video_monitor == '0' ? '无' : '有' }}</div>
					</el-col>
					<el-col :span="8" class="flex">
						<div class="bg-title">周边设施配套</div>
						<el-tooltip class="box-item" effect="light" :content="initData.sbi_supporting_facilities_txt"
							placement="bottom">
							<div class="contents">{{ initData.sbi_supporting_facilities_txt }}</div>
						</el-tooltip>
					</el-col>
					<el-col :span="8" class="flex">
						<div class="bg-title">是否有小票机</div>
						<div class="contents">{{ initData.sbi_printer_flag == '0' ? '无' : '有' }}
						</div>
					</el-col>
					<el-col :span="8" class="flex">
						<div class="bg-title">是否有道闸</div>
						<div class="contents">{{ initData.sbi_barrier_flag == '0' ? '无' : '有' }}
						</div>
					</el-col>
					<el-col :span="8" class="flex">
						<div class="bg-title">是否有地锁</div>
						<div class="contents">{{ initData.sbi_parking_lock_flag == '0' ? '无' : '有' }}
						</div>
					</el-col>
					<el-col :span="8" class="flex">
						<div class="bg-title">设备所属方名称</div>
						<el-tooltip class="box-item" effect="light" :content="initData.sbi_equipment_owner_name"
							placement="bottom">
							<div class="contents">{{ initData.sbi_equipment_owner_name }}</div>
						</el-tooltip>
					</el-col>
					<el-col :span="8" class="flex">
						<div class="bg-title">供电局用户编号</div>
						<el-tooltip class="box-item" effect="light" :content="initData.sbi_resident_no"
							placement="bottom">
							<div class="contents">{{ initData.sbi_resident_no }}</div>
						</el-tooltip>
					</el-col>
					<el-col :span="8" class="flex">
						<div class="bg-title">供电类型</div>
						<el-tooltip class="box-item" effect="light" :content="initData.sbi_supply_type_txt"
							placement="bottom">
							<div class="contents">{{ initData.sbi_supply_type_txt }}</div>
						</el-tooltip>
					</el-col>
					<el-col :span="8" class="flex">
						<div class="bg-title">表号</div>
						<el-tooltip class="box-item" effect="light" :content="initData.sbi_watt_hour_meter_no"
							placement="bottom">
							<div class="contents">{{ initData.sbi_watt_hour_meter_no }}</div>
						</el-tooltip>
					</el-col>
					<el-col :span="8" class="flex">
						<div class="bg-title">外电功率</div>
						<el-tooltip class="box-item" effect="light" :content="initData.sbi_forward_power"
							placement="bottom">
							<div class="contents">{{ initData.sbi_forward_power }}</div>
						</el-tooltip>
					</el-col>
					<el-col :span="8" class="flex">
						<div class="bg-title">全省唯一备案号</div>
						<el-tooltip class="box-item" effect="light" :content="initData.sbi_record_unique_no"
							placement="bottom">
							<div class="contents">{{ initData.sbi_record_unique_no }}</div>
						</el-tooltip>
					</el-col>
					<el-col :span="24" class="flex">
						<div class="bg-title">使用车型描述</div>
						<el-tooltip class="box-item" effect="light" :content="initData.sbi_match_car"
							placement="bottom">
							<div class="contents">{{ initData.sbi_match_car }}</div>
						</el-tooltip>
					</el-col>
					<el-col :span="24" class="flex">
						<div class="bg-title">服务车型描述</div>
						<el-tooltip class="box-item" effect="light" :content="initData.sbi_swap_match_cars"
							placement="bottom">
							<div class="contents">{{ initData.sbi_swap_match_cars }}</div>
						</el-tooltip>
					</el-col>
				</el-row>
			</div>
		</w-dialog>
	</div>
</template>

<script setup>
	import { reactive, ref, } from 'vue';
	import { ElMessage, } from "element-plus";
	import { SystemManage, } from "@/plugins/api.js";

	const refWdialog = ref(null);  // 对话框对象
	const initData = ref({});  // 详情数据
	/**
	 * 
	 * 打开弹框
	 * 
	 * **/
	const openDialog = (s_id) => {
		getInit(s_id);
		refWdialog.value.show()
	}
	/**
	 * 
	 * 获取初始化数据
	 * 
	 * **/
	const getInit = (s_id) => {
		SystemManage.getSuperviseInfo({ s_id }).then((res) => {
			if (res.Code === 200) {
				initData.value = res.Data;
			} else {
				ElMessage.error(res.Message);
			}
		});
	}

	defineExpose({
		openDialog
	})
</script>

<style lang="scss" scoped>
	.flex {
		display: flex;
	}

	.bg-title {
		padding: 10px;
		min-width: 140px;
		color: var(--text-third-color);
	}

	.contents {
		padding: 10px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		-webkit-line-clamp: 1;
		color: var(--text-color);
		width: 100%;
	}

	.stand-title {
		font-size: 16px;
		font-weight: bold;
		margin: 10px 0;
	}

	.supervise-content {
		overflow-y: auto;
		max-height: 600px;
		overflow-x: clip;
	}

	.basic-title {
		color: var(--theme-color);
		padding-bottom: 10px;
		display: flex;
	}

	.circle {
		z-index: 999;
		height: 20px;
		width: 20px;
		border-radius: 50%;
		background-color: var(--theme-color);
		color: #fff;
		text-align: center;
		line-height: 20px;
		margin-left: 5px;
		position: absolute;
		left: 60px;
		top: 11px;
		cursor: pointer;
	}

	.el-input-group__append {
		color: #000;
		border: none;
	}
</style>