<!-- 监管配置 -->

<template>
	<div class="supervise main-cnt">
		<div class="content">
			<common-table ref="chargeTable" tableHeight="calc(100vh - 325px)" :ischeck="false" :ispaging="true"
				:apiName="SystemManage.getSuperviseStationLists" :columns="tableColumns" @onDetailsBtn="onDetailsBtn"
				@onEditBtn="onEditBtn">
				<template #manager_list='{row}'>
					<div>
						{{row.province_name}}{{row.city_name}}{{row.county_name}}
					</div>
				</template>

				<template #platform='{row}'>
					<span v-if="row.operator_supervise.length == 0">-</span>
					<div class="text-hidden">
						<div v-for="(item, i) in row.operator_supervise" :key="i" class="flex align-center">
							<img src="@/assets/img/occupy.png" v-if="item.listen_status == '2'" class="platform-img" />
							<img src="@/assets/img/fault.png" v-if="item.listen_status == '3'"
								class="platform-img" />
							<div
								:class="item.listen_status == '2' ? 'title-num-orange' : item.listen_status == '3' ? 'title-num-red' : ''">
								{{ item.osp_name }}</div>
						</div>
					</div>
				</template>
			</common-table>
		</div>

		<!-- 编辑页面 -->
		<EditSupervision ref="editRef" :tableLoad="tableLoad"></EditSupervision>

		<!-- 详情 -->
		<RegulatoryDetails ref="infoRef"></RegulatoryDetails>
	</div>
</template>

<script setup>
	import { ref, onMounted, computed, watch, } from "vue";
	import { SystemManage, } from "@/plugins/api.js";
	import { ElMessage, } from "element-plus";
	import { useStore, } from "vuex";
	import EditSupervision from '../components/EditSupervision.vue';
	import RegulatoryDetails from '../components/RegulatoryDetails.vue';

	const store = useStore();
	const menuTokens = computed(() => store.state.menuToken.menuTokens);
	const authData = ref([]);
	watch(
		() => menuTokens.value,
		(data) => {
			if (data.length) {
				authData.value = data;
			}
		}, {
		deep: true,
		immediate: true,
	}
	);
	/** 表格对象 */
	const chargeTable = ref(null);
	/** 表格配置数据 */
	const tableColumns = ref([
		{
			prop: "s_id",
			label: "ID",
			color: "--text-color",
		},
		{
			prop: "s_no",
			label: "电站编号",
			color: "--text-color",
		},
		{
			type: "customRender",
			prop: "platform",
			label: "监管平台",
			minWidth: 280,
		},
		{
			prop: "s_name",
			label: "电站名称",
			color: "--text-color",
		},
		{
			type: "customRender",
			prop: "manager_list",
			label: "区域",
			color: "--text-color",
		},
		{
			prop: "s_busine_hours",
			label: "营业时间",
			color: "--text-color",
		},
		{
			type: "operation",
			prop: "",
			label: "操作",
			minWidth: 200,
			bottons: [
				{
					name: "详情",
					action: "onDetailsBtn",
					token: "members",
				},
				{
					name: "编辑",
					action: "onEditBtn",
					token: "members",
				},
			],
		},
	]);
	const editRef = ref(null);  // 编辑弹框对象
	const infoRef = ref(null);  // 详情弹框对象
	/**
	 * 
	 * 详情按钮
	 * 
	 * */
	const onDetailsBtn = (row) => {
		infoRef.value.openDialog(row.s_id);
	}
	/**
	 * 
	 * 编辑
	 * 
	 * **/
	const onEditBtn = (row) => {
		editRef.value.openDialog(row);
	}
	/**
	 * 
	 * 编辑成功重新获取列表数据
	 * 
	 * **/
	const tableLoad = () => {
		chargeTable.value.tableLoad();
	}

	onMounted(() => {
		chargeTable.value.tableLoad();
	});
</script>

<style lang="scss" scoped>
	.el-textarea__inner {
		background-color: var(--search-uncheck-bg-color);
		border: none;
		color: var(--text-color);
	}

	.el-row {
		border-radius: 5px;
		border: 1px solid var(--border-color);
		margin-bottom: 10px;
	}

	.col {
		display: flex;
	}

	.supervise {
		font-family: "Source Han Sans CN";

		.content {
			padding: 20px;
		}

		.add-dialog {
			.el-dialog__body {
				padding: 10px 15px 15px 30px;
			}
		}
	}

	.platform-img {
		width: 20px;
		height: 20px;
		margin-right: 5px;
	}
</style>