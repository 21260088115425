<!-- 编辑监管配置 -->

<template>
	<div>
		<w-dialog ref="refWdialog" class="add-dialog" :modalClick="false" title="编辑" width="70%" btnWidth="140px"
			top="5vh" @wConfirm="wConfirm">
			<div class="stand-title">{{ currentRow.s_name }}</div>
			<div class="supervise-content">
				<el-form labelPosition="top" ref="ruleFormRef" v-loading="loading" :model="ruleForm" :rules="rules">
					<el-row :gutter="20">
						<el-col :span="8">
							<el-form-item label="监管平台互联互通" prop="sbi_osp_ids">
								<el-select v-model="ruleForm.sbi_osp_ids" multiple collapse-tags clearable
									placeholder="请选择监管平台互联互通">
									<el-option v-for="(item, i) in initData.operator_supervise" :key="i"
										:label="item.osp_name" :value="item.osp_id" />
								</el-select>
							</el-form-item>
						</el-col>

						<el-col :span="8">
							<el-form-item label="唯一编码" prop="s_no">
								<el-input v-model="ruleForm.s_no" placeholder="请输入唯一编码" readonly></el-input>
							</el-form-item>
						</el-col>

						<el-col :span="8">
							<el-form-item label="平台运营商ID" prop="o_name">
								<el-input v-model="ruleForm.o_name" readonly></el-input>
							</el-form-item>
						</el-col>

						<el-col :span="8">
							<el-form-item label="国家代码" prop="sbi_country_code">
								<el-select v-model="ruleForm.sbi_country_code" clearable placeholder="请选择国家代码">
									<el-option v-for="(item, i) in initData.area_country_code" :key="i"
										:label="item.chinese_name" :value="item.two_code" />
								</el-select>
							</el-form-item>
						</el-col>

						<el-col :span="8">
							<el-form-item label="省市辖区编码" prop="sbi_area_code">
								<el-cascader v-if="isShowProvince" v-model="ruleForm.sbi_area_code"
									:props="jurisdicCodeProps" clearable placeholder="请选择省市辖区编码" />
							</el-form-item>
						</el-col>

						<el-col :span="8">
							<el-form-item label="行政区县" prop="sbi_area_code_countryside">
								<el-cascader v-if="isShowCounty" v-model="ruleForm.sbi_area_code_countryside"
									:props="countyCodeProps" clearable placeholder="请选择行政区县" />
							</el-form-item>
						</el-col>

						<el-col :span="8">
							<el-form-item label="详细地址" prop="sbi_address">
								<el-input v-model="ruleForm.sbi_address" placeholder="请输入详细地址"></el-input>
							</el-form-item>
						</el-col>

						<el-col :span="8">
							<el-form-item label="站点电话" prop="sbi_station_tel">
								<el-input v-model="ruleForm.sbi_station_tel" type="number" placeholder="请输入站点电话">
								</el-input>
							</el-form-item>
						</el-col>

						<el-col :span="8">
							<el-form-item label="服务电话" prop="sbi_service_tel">
								<el-input v-model="ruleForm.sbi_service_tel" type="number" placeholder="请输入服务电话">
								</el-input>
							</el-form-item>
						</el-col>

						<el-col :span="8">
							<el-form-item label="站点分类" prop="sbi_station_classification">
								<el-select v-model="ruleForm.sbi_station_classification" clearable
									placeholder="请选择站点分类">
									<el-option v-for="(item, i) in initData.site_classification" :key="i"
										:label="item.label" :value="item.value" />
								</el-select>
							</el-form-item>
						</el-col>

						<el-col :span="8">
							<el-form-item label="通用类型" prop="sbi_general_application_type">
								<el-select v-model="ruleForm.sbi_general_application_type" clearable
									placeholder="请选择通用类型">
									<el-option v-for="(item, i) in initData.generic_type" :key="i" :label="item.label"
										:value="item.value" />
								</el-select>
							</el-form-item>
						</el-col>

						<el-col :span="8">
							<el-form-item label="站点类型" prop="sbi_st_id">
								<el-select v-model="ruleForm.sbi_st_id" clearable placeholder="请选择站点类型">
									<el-option v-for="(item, i) in initData.site_type" :key="i" :label="item.label"
										:value="item.value" />
								</el-select>
							</el-form-item>
						</el-col>

						<el-col :span="8">
							<el-form-item label="站点状态" prop="s_status">
								<el-select v-model="ruleForm.s_status" clearable placeholder="请选择站点状态">
									<el-option v-for="(item, i) in initData.site_status" :key="i" :label="item.label"
										:value="item.value" />
								</el-select>
							</el-form-item>
						</el-col>

						<el-col :span="8">
							<el-form-item label="车位数量" prop="sbi_park_nums">
								<el-input v-model="ruleForm.sbi_park_nums" type="number" placeholder="请输入车位数量">
								</el-input>
							</el-form-item>
						</el-col>

						<el-col :span="8">
							<el-form-item label="经度" prop="sbi_lng">
								<el-input v-model="ruleForm.sbi_lng" placeholder="请输入经度"></el-input>
							</el-form-item>
						</el-col>

						<el-col :span="8">
							<el-form-item label="纬度" prop="sbi_lat">
								<el-input v-model="ruleForm.sbi_lat" placeholder="请输入纬度"></el-input>
							</el-form-item>
						</el-col>

						<el-col :span="8">
							<el-form-item label="站点引导" prop="sbi_site_guide">
								<el-input v-model="ruleForm.sbi_site_guide" placeholder="请输入站点引导"></el-input>
							</el-form-item>
						</el-col>

						<el-col :span="8">
							<el-form-item label="建设场所" prop="sbi_sc_id">
								<el-select v-model="ruleForm.sbi_sc_id" clearable placeholder="请选择建设场所">
									<el-option v-for="(item, i) in initData.construction_site" :key="i"
										:label="item.label" :value="item.value" />
								</el-select>
							</el-form-item>
						</el-col>

						<el-col :span="8">
							<el-form-item label="7*24小时营业" prop="sbi_round_the_clock">
								<el-select v-model="ruleForm.sbi_round_the_clock" disabled placeholder="请选择是否24小时营业">
									<el-option label="否" value="0"></el-option>
									<el-option label="是" value="1"></el-option>
								</el-select>
							</el-form-item>
						</el-col>

						<el-col :span="8">
							<el-form-item label="营业时间" prop="sbi_busine_hours">
								<el-input v-model="ruleForm.sbi_busine_hours" readonly placeholder="请输入营业时间"></el-input>
							</el-form-item>
						</el-col>

						<el-col :span="8">
							<el-form-item label="停车费类型" prop="sbi_park_type">
								<el-select v-model="ruleForm.sbi_park_type" clearable placeholder="请选择停车费类型">
									<el-option v-for="(item, i) in initData.parking_fee_type" :key="i"
										:label="item.label" :value="item.value" />
								</el-select>
							</el-form-item>
						</el-col>

						<el-col :span="8">
							<el-form-item label="停车费描述" prop="sbi_park_fee">
								<el-input v-model="ruleForm.sbi_park_fee" placeholder="请输入停车费描述"></el-input>
							</el-form-item>
						</el-col>

						<el-col :span="8">
							<el-form-item label="支付方式" prop="sbi_payment">
								<el-select v-model="ruleForm.sbi_payment" clearable placeholder="请选择支付方式">
									<el-option v-for="(item, i) in initData.payment" :key="i" :label="item"
										:value="item" />
								</el-select>
							</el-form-item>
						</el-col>

						<el-col :span="8">
							<el-form-item label="是否支持预约" prop="sbi_support_order">
								<el-select v-model="ruleForm.sbi_support_order" clearable placeholder="请选择是否支持预约">
									<el-option label="不支持预约" value="0"></el-option>
									<el-option label="支持预约" value="1"></el-option>
								</el-select>
							</el-form-item>
						</el-col>

						<el-col :span="8">
							<el-form-item label="备注" prop="sbi_remark">
								<el-input v-model="ruleForm.sbi_remark" placeholder="请输入备注"></el-input>
							</el-form-item>
						</el-col>

						<el-col :span="8">
							<el-form-item label="电费类型" prop="sbi_electricity_type">
								<el-select v-model="ruleForm.sbi_electricity_type" clearable placeholder="请选择电费类型">
									<el-option v-for="(item, i) in initData.electricity_bill_type" :key="i"
										:label="item.label" :value="item.value" />
								</el-select>
							</el-form-item>
						</el-col>

						<el-col :span="8">
							<el-form-item label="报装类型" prop="sbi_business_expand_type">
								<el-select v-model="ruleForm.sbi_business_expand_type" clearable
									placeholder="请选择是否独立报装">
									<el-option label="否" value="0"></el-option>
									<el-option label="是" value="1"></el-option>
								</el-select>
							</el-form-item>
						</el-col>

						<el-col :span="8">
							<el-form-item label="报装电源容量" prop="sbi_capacity">
								<el-input v-model="ruleForm.sbi_capacity" placeholder="请输入报装电源容量" type="number">
								</el-input>
							</el-form-item>
						</el-col>

						<el-col :span="8">
							<el-form-item label="站点额定总功率" prop="sbi_rated_power">
								<el-input v-model="ruleForm.sbi_rated_power" placeholder="请输入站点额定总功率" type="number">
									<template #append>
										<div style="color: #000;">KW</div>
									</template>
								</el-input>
							</el-form-item>
						</el-col>

						<el-col :span="8">
							<el-form-item label="峰谷分时" prop="sbi_period_fee">
								<el-select v-model="ruleForm.sbi_period_fee" clearable placeholder="请选择峰谷分时">
									<el-option label="否" value="0"></el-option>
									<el-option label="是" value="1"></el-option>
								</el-select>
							</el-form-item>
						</el-col>

						<el-col :span="8">
							<el-form-item label="正式投运时间" prop="sbi_official_runtime">
								<el-date-picker v-model="ruleForm.sbi_official_runtime" type="date"
									placeholder="请选择正式投运时间" format="YYYY-MM-DD" value-format="YYYY-MM-DD" />
							</el-form-item>
						</el-col>

						<el-col :span="8">
							<el-form-item label="充换电方位" prop="sbi_station_orientation">
								<el-select v-model="ruleForm.sbi_station_orientation" clearable placeholder="请选择充换电方位">
									<el-option v-for="(item, i) in initData.charging_and_swapping_directions" :key="i"
										:label="item.label" :value="item.value" />
								</el-select>
							</el-form-item>
						</el-col>

						<el-col :span="8">
							<el-form-item label="充换电站建筑面积" prop="sbi_station_area">
								<el-input v-model="ruleForm.sbi_station_area" placeholder="请输入充换电站建筑面积" type="number">
								</el-input>
							</el-form-item>
						</el-col>

						<el-col :span="8">
							<el-form-item label="充换电站人工值守" prop="sbi_have_person">
								<el-select v-model="ruleForm.sbi_have_person" clearable placeholder="请选择充换电站人工值守">
									<el-option label="无" value="0"></el-option>
									<el-option label="有" value="1"></el-option>
								</el-select>
							</el-form-item>
						</el-col>

						<el-col :span="8">
							<el-form-item label="视频监控配套情况" prop="sbi_video_monitor">
								<el-select v-model="ruleForm.sbi_video_monitor" clearable placeholder="请选择视频监控配套情况">
									<el-option label="无" value="0"></el-option>
									<el-option label="有" value="1"></el-option>
								</el-select>
							</el-form-item>
						</el-col>

						<el-col :span="8">
							<el-form-item label="周边设施配套" prop="sbi_supporting_facilities">
								<el-select v-model="ruleForm.sbi_supporting_facilities" multiple collapse-tags
									placeholder="请选择周边设施配套">
									<el-option v-for="(item, i) in initData.surrounding_facilities" :key="i"
										:label="item.label" :value="item.value" />
								</el-select>
							</el-form-item>
						</el-col>

						<el-col :span="8">
							<el-form-item label="是否有小票机" prop="sbi_printer_flag">
								<el-select v-model="ruleForm.sbi_printer_flag" clearable placeholder="请选择是否有小票机">
									<el-option label="无" value="0"></el-option>
									<el-option label="有" value="1"></el-option>
								</el-select>
							</el-form-item>
						</el-col>

						<el-col :span="8">
							<el-form-item label="是否有道闸" prop="sbi_barrier_flag">
								<el-select v-model="ruleForm.sbi_barrier_flag" clearable placeholder="请选择是否有道闸">
									<el-option label="无" value="0"></el-option>
									<el-option label="有" value="1"></el-option>
								</el-select>
							</el-form-item>
						</el-col>

						<el-col :span="8">
							<el-form-item label="是否有地锁" prop="sbi_parking_lock_flag">
								<el-select v-model="ruleForm.sbi_parking_lock_flag" clearable placeholder="请选择是否有地锁">
									<el-option label="无" value="0"></el-option>
									<el-option label="有" value="1"></el-option>
								</el-select>
							</el-form-item>
						</el-col>

						<el-col :span="8">
							<el-form-item label="设备所属方名称" prop="sbi_equipment_owner_name">
								<el-input v-model="ruleForm.sbi_equipment_owner_name" placeholder="请输入设备所属方名称">
								</el-input>
							</el-form-item>
						</el-col>

						<el-col :span="8">
							<el-form-item label="供电局用户编号" prop="sbi_resident_no">
								<el-input v-model="ruleForm.sbi_resident_no" placeholder="请输入供电局用户编号">
								</el-input>
							</el-form-item>
						</el-col>

						<el-col :span="8">
							<el-form-item label="供电类型" prop="sbi_supply_type">
								<el-select v-model="ruleForm.sbi_supply_type" clearable placeholder="请选择供电类型">
									<el-option v-for="(item, i) in initData.power_supply_type" :key="i"
										:label="item.label" :value="item.value" />
								</el-select>
							</el-form-item>
						</el-col>

						<el-col :span="8">
							<el-form-item label="表号" prop="sbi_watt_hour_meter_no">
								<el-input v-model="ruleForm.sbi_watt_hour_meter_no" placeholder="请输入表号"></el-input>
							</el-form-item>
						</el-col>

						<el-col :span="8">
							<el-form-item label="外电功率" prop="sbi_forward_power">
								<el-input v-model="ruleForm.sbi_forward_power" placeholder="请输入外电功率"></el-input>
							</el-form-item>
						</el-col>

						<el-col :span="8">
							<el-form-item label="全省唯一备案号" prop="sbi_record_unique_no">
								<el-input v-model="ruleForm.sbi_record_unique_no" placeholder="请输入全省唯一备案号"></el-input>
							</el-form-item>
						</el-col>

						<el-col :span="24">
							<el-form-item label="使用车型描述" prop="sbi_match_car">
								<el-input v-model.trim="ruleForm.sbi_match_car" type="textarea" rows="3"
									placeholder="请输入使用车型描述">
								</el-input>
							</el-form-item>
						</el-col>

						<el-col :span="24">
							<el-form-item label="服务车型描述" prop="sbi_swap_match_cars">
								<el-input v-model.trim="ruleForm.sbi_swap_match_cars" type="textarea" rows="3"
									placeholder="请输入服务车型描述">
								</el-input>
							</el-form-item>
						</el-col>
					</el-row>
				</el-form>
			</div>
		</w-dialog>
	</div>
</template>

<script setup>
	import { reactive, ref, } from 'vue';
	import { ElMessage, } from "element-plus";
	import { SystemManage, } from "@/plugins/api.js";
	import { nextTick, } from 'vue';

	const props = defineProps({
		tableLoad: {
			type: Function,
			default: null
		}
	})
	const isShowProvince = ref(false);  // 重新加载Cascader 级联选择器
	const isShowCounty = ref(false);  // 重新加载Cascader 级联选择器
	const ruleForm = reactive({
		s_id: '',  // 电站id
		sbi_osp_ids: '', // 监管平台互联互通账号ID
		s_no: '',  // 唯一编码
		o_name: '', // 运营商
		sbi_country_code: '', // 国家代码
		sbi_area_code: '', // 省市辖区编码
		sbi_area_code_countryside: '', // 行政区域代码
		sbi_address: '', // 详细地址
		sbi_service_tel: '',  // 服务电话
		sbi_station_classification: '',  // 站点分类
		sbi_general_application_type: '', // 通用类型
		sbi_st_id: '', // 站点类型
		s_status: '', // 站点状态
		sbi_park_nums: '', // 车位数量
		sbi_lng: '', // 站点经度
		sbi_lat: '', // 站点纬度
		sbi_site_guide: '', // 站点引导
		sbi_sc_id: '', // 站点建设场所
		sbi_busine_hours: '', // 营业时间
		sbi_round_the_clock: '', // 是否24小时营业
		sbi_park_type: '', // 站点停车费类型
		sbi_park_fee: '', // 停车费描述
		sbi_support_order: '',  // 是否支持预约
		sbi_remark: '',  // 备注
		sbi_electricity_type: '',  // 电费类型
		sbi_business_expand_type: '',  // 报装类型
		sbi_capacity: '',  // 报装电源容量
		sbi_rated_power: '',  // 站点额定总功率
		sbi_period_fee: '',  // 峰谷分时
		sbi_official_runtime: '',  // 正式投运时间
		sbi_station_orientation: '',  // 充换电方位
		sbi_station_area: '',  // 充换电站建筑面积
		sbi_have_person: '',  // 充换电站人工值守
		sbi_video_monitor: '',  // 视频监控配套情况
		sbi_supporting_facilities: '',  // 周边设施配套
		sbi_printer_flag: '',  // 小票机
		sbi_barrier_flag: '',  // 道闸
		sbi_parking_lock_flag: '',  // 地锁
		sbi_equipment_owner_name: '',  // 设备所属方名称
		sbi_supply_type: '',  // 供电类型
		sbi_watt_hour_meter_no: '',  // 表号
		sbi_forward_power: '',  // 外电功率
		sbi_record_unique_no: '',  // 全省唯一备案号
		sbi_match_car: '',  // 使用车型描述
		sbi_swap_match_cars: '',  // 服务车型描述
		sbi_resident_no: '',  // 供电局用户编号
		sbi_station_tel: '',  // 站点电话
		sbi_payment: '',  // 支付方式
	})
	const rules = reactive({  // 验证表单必填项
		sbi_osp_ids: [{ required: true, message: '请选择监管平台互联互通', trigger: 'change' }],
		sbi_country_code: [{ required: true, message: '请选择国家代码', trigger: 'change' }],
		sbi_area_code: [{ required: true, message: '请选择省市辖区编码', trigger: 'change' }],
		sbi_area_code_countryside: [{ required: true, message: '请选择行政区县', trigger: 'change' }],
		sbi_address: [{ required: true, message: '请输入详细地址', trigger: 'blur' }],
		sbi_station_tel: [{ required: true, message: '请输入站点电话', trigger: 'blur' }],
		sbi_service_tel: [{ required: true, message: '请输入服务电话', trigger: 'blur' }],
		sbi_station_classification: [{ required: true, message: '请选择站点分类', trigger: 'change' }],
		sbi_st_id: [{ required: true, message: '请选择站点类型', trigger: 'change' }],
		s_status: [{ required: true, message: '请选择站点状态', trigger: 'change' }],
		sbi_park_nums: [{ required: true, message: '请输入车位数量', trigger: 'blur' }],
		sbi_lng: [{ required: true, message: '请输入经度', trigger: 'blur' }],
		sbi_lat: [{ required: true, message: '请输入纬度', trigger: 'blur' }],
		sbi_site_guide: [{ required: true, message: '请输入站点引导', trigger: 'blur' }],
		sbi_sc_id: [{ required: true, message: '请选择建设场所', trigger: 'change' }],
		sbi_busine_hours: [{ required: true, message: '请输入营业时间', trigger: 'blur' }],
		sbi_round_the_clock: [{ required: true, message: '请选择是否24小时营业', trigger: 'change' }],
		sbi_park_type: [{ required: true, message: '请选择停车费类型', trigger: 'change' }],
		sbi_park_fee: [{ required: true, message: '请输入停车费描述', trigger: 'blur' }],
		sbi_payment: [{ required: true, message: '请选择支付方式', trigger: 'change' }],
		sbi_support_order: [{ required: true, message: '请选择是否支持预约', trigger: 'change' }],
		sbi_remark: [{ required: true, message: '请输入备注', trigger: 'blur' }],
		sbi_electricity_type: [{ required: true, message: '请选择电费类型', trigger: 'change' }],
		sbi_business_expand_type: [{ required: true, message: '请选择是否独立报装', trigger: 'change' }],
		sbi_rated_power: [{ required: true, message: '请输入站点额定总功率', trigger: 'blur' }],
		sbi_period_fee: [{ required: true, message: '请选择峰谷分时', trigger: 'change' }],
		sbi_official_runtime: [{ required: true, message: '请选择正式投运时间', trigger: 'change' }],
		sbi_station_orientation: [{ required: true, message: '请选择充换电方位', trigger: 'change' }],
		sbi_station_area: [{ required: true, message: '请输入充换电站建筑面积', trigger: 'blur' }],
		sbi_have_person: [{ required: true, message: '请选择充换电站人工值守', trigger: 'change' }],
		sbi_video_monitor: [{ required: true, message: '请选择视频监控配套情况', trigger: 'change' }],
		sbi_printer_flag: [{ required: true, message: '请选择是否有小票机', trigger: 'change' }],
		sbi_barrier_flag: [{ required: true, message: '请选择是否有道闸', trigger: 'change' }],
		sbi_parking_lock_flag: [{ required: true, message: '请选择是否有地锁', trigger: 'change' }],
		sbi_equipment_owner_name: [{ required: true, message: '请输入设备所属方名称', trigger: 'blur' }],
		sbi_resident_no: [{ required: true, message: '请输入供电局用户编号', trigger: 'blur' }],
		sbi_supply_type: [{ required: true, message: '请选择供电类型', trigger: 'change' }],
		sbi_watt_hour_meter_no: [{ required: true, message: '请输入表号', trigger: 'blur' }],
		sbi_forward_power: [{ required: true, message: '请输入外电功率', trigger: 'blur' }],
		sbi_record_unique_no: [{ required: true, message: '请输入全省唯一备案号', trigger: 'blur' }],
		sbi_match_car: [{ required: true, message: '请输入使用车型描述', trigger: 'blur' }],
	})
	const refWdialog = ref(null);  // 对话框对象
	const ruleFormRef = ref(null);  // 表单对象
	const loading = ref(false);  // 表单加载状态
	const currentRow = ref({});  // 当前行编辑数据
	const initData = ref({});  // 初始化数据
	/**
	 * 
	 * 打开弹框
	 * 
	 * **/
	const openDialog = (row) => {
		currentRow.value = row;
		getEditInit();
		getDetails(row.s_id);
		refWdialog.value.show()
		nextTick(() => {
			ruleFormRef.value.resetFields();
			ruleForm.s_id = row.s_id;
			isShowProvince.value = true;
			isShowCounty.value = true;
		})
	}
	/**
	 * 
	 * 获取初始化数据
	 * 
	 * **/
	const getEditInit = (s_id) => {
		SystemManage.getEditInit({ s_id: currentRow.value.s_id }).then((res) => {
			if (res.Code === 200) {
				initData.value = res.Data;
			} else {
				ElMessage.error(res.Message);
			}
		});
	}
	/**
	 * 
	 * 获取详情数据
	 * 
	 * **/
	const getDetails = (s_id) => {
		SystemManage.getSuperviseInfo({ s_id }).then((res) => {
			if (res.Code === 200) {
				ruleForm.sbi_osp_ids = res.Data.sbi_osp_ids; // 监管平台互联互通账号ID
				ruleForm.s_no = res.Data.s_no;
				ruleForm.o_name = res.Data.o_name;
				ruleForm.sbi_country_code = res.Data.s_country_code; // 国家代码
				ruleForm.sbi_area_code = res.Data.sbi_area_code_tree; // 省市辖区编码
				ruleForm.sbi_area_code_countryside = res.Data.sbi_area_code_countryside_tree; // 行政区域代码
				ruleForm.sbi_address = res.Data.sbi_address; // 详细地址
				ruleForm.sbi_station_tel = res.Data.sbi_station_tel;  // 站点电话
				ruleForm.sbi_service_tel = res.Data.sbi_service_tel;  // 服务电话
				ruleForm.sbi_station_classification = res.Data.sbi_station_classification;  // 站点分类
				ruleForm.sbi_general_application_type = res.Data.sbi_general_application_type; // 通用类型
				ruleForm.sbi_st_id = res.Data.sbi_st_id; // 站点类型
				ruleForm.s_status = res.Data.s_status; // 站点状态
				ruleForm.sbi_park_nums = res.Data.sbi_park_nums; // 车位数量
				ruleForm.sbi_lng = res.Data.sbi_lng; // 站点经度
				ruleForm.sbi_lat = res.Data.sbi_lat; // 站点纬度
				ruleForm.sbi_site_guide = res.Data.sbi_site_guide; // 站点引导
				ruleForm.sbi_sc_id = res.Data.sbi_sc_id; // 站点建设场所
				ruleForm.sbi_busine_hours = res.Data.sbi_busine_hours; // 营业时间
				ruleForm.sbi_round_the_clock = String(res.Data.sbi_round_the_clock); // 是否24小时营业
				ruleForm.sbi_park_type = res.Data.sbi_park_type; // 站点停车费类型
				ruleForm.sbi_park_fee = res.Data.sbi_park_fee; // 停车费描述
				ruleForm.sbi_support_order = String(res.Data.sbi_support_order);  // 是否支持预约
				ruleForm.sbi_remark = res.Data.sbi_remark;  // 备注
				ruleForm.sbi_electricity_type = res.Data.sbi_electricity_type;  // 电费类型
				ruleForm.sbi_business_expand_type = String(res.Data.sbi_business_expand_type);  // 报装类型
				ruleForm.sbi_capacity = res.Data.sbi_capacity;  // 报装电源容量
				ruleForm.sbi_rated_power = res.Data.sbi_rated_power;  // 站点额定总功率
				ruleForm.sbi_period_fee = String(res.Data.sbi_period_fee);  // 峰谷分时
				ruleForm.sbi_official_runtime = res.Data.sbi_official_runtime;  // 正式投运时间
				ruleForm.sbi_station_orientation = res.Data.sbi_station_orientation;  // 充换电方位
				ruleForm.sbi_station_area = res.Data.sbi_station_area;  // 充换电站建筑面积
				ruleForm.sbi_have_person = String(res.Data.sbi_have_person);  // 充换电站人工值守
				ruleForm.sbi_video_monitor = String(res.Data.sbi_video_monitor);  // 视频监控配套情况
				ruleForm.sbi_supporting_facilities = res.Data.sbi_supporting_facilities;  // 周边设施配套
				ruleForm.sbi_printer_flag = String(res.Data.sbi_printer_flag);  // 小票机
				ruleForm.sbi_barrier_flag = String(res.Data.sbi_barrier_flag);  // 道闸
				ruleForm.sbi_parking_lock_flag = String(res.Data.sbi_parking_lock_flag);  // 地锁
				ruleForm.sbi_equipment_owner_name = res.Data.sbi_equipment_owner_name;  // 设备所属方名称
				ruleForm.sbi_supply_type = res.Data.sbi_supply_type;  // 供电类型
				ruleForm.sbi_watt_hour_meter_no = res.Data.sbi_watt_hour_meter_no;  // 表号
				ruleForm.sbi_forward_power = res.Data.sbi_forward_power;  // 外电功率
				ruleForm.sbi_record_unique_no = res.Data.sbi_record_unique_no;  // 全省唯一备案号
				ruleForm.sbi_match_car = res.Data.sbi_match_car;  // 使用车型描述
				ruleForm.sbi_swap_match_cars = res.Data.sbi_swap_match_cars;  // 服务车型描述
				ruleForm.sbi_resident_no = res.Data.sbi_resident_no;  // 供电局用户编号
				ruleForm.sbi_payment = res.Data.sbi_payment;  // 支付方式

				isShowCounty.value = true;
			} else {
				ElMessage.error(res.Message);
			}
		});
	}
	/**
	 * 
	 * 获取省市辖区编码
	 * 
	 * **/
	const jurisdicCodeProps = {
		lazy: true,
		lazyLoad(node, resolve) {
			const { level } = node;
			let param = level == 0 ? '' : node.value;
			SystemManage.getAreaCode({
				type: level + 1,
				code: param,
			}).then((res) => {
				if (res.Code === 200) {
					let nodes = [];
					if (res.Data) {
						nodes = res.Data.map((val) => {
							return {
								value: val.code,
								label: val.name,
								leaf: level >= 2,
							}
						})
					}
					resolve(nodes);
				} else {
					ElMessage.error(res.Message);
				}
			});
		}
	}
	/**
	 * 
	 * 获取行政区县
	 * 
	 * **/
	const countyCodeProps = {
		lazy: true,
		checkStrictly: true,
		lazyLoad(node, resolve) {
			const { level } = node;
			SystemManage.getAreaCodeCountryside({ p_code: node.value, }).then((res) => {
				if (res.Code === 200) {
					let nodes = [];
					if (res.Data) {
						nodes = res.Data.map((val) => {
							return {
								value: val.code,
								label: val.area_code_countryside,
								leaf: level >= 4,
							}
						})
					}
					resolve(nodes);
				} else {
					ElMessage.error(res.Message);
				}
			});
		}
	}
	/**
	 * 
	 * 确定按钮
	 * 
	 * **/
	const wConfirm = () => {
		ruleFormRef.value.validate((valid, fields) => {
			if (valid) {
				// 当站点类型为换电站或者充换电一体站的时候，通用类型为必选，当站点类型为充电站的时候，为非必填
				if (ruleForm.sbi_station_classification == '2' || ruleForm.sbi_station_classification == '3') {
					if (ruleForm.sbi_general_application_type == '') {
						ElMessage.error("请选择通用类型");
						return false;
					}
					// 当站点分类为换电站、充换电一体站时，服务车型描述必填
					if (ruleForm.sbi_swap_match_cars == '') {
						ElMessage.error("请输入服务车型描述");
						return false;
					}
				}
				// 若报装类型为是独立报装，则需要填写电源容量；如果不是，则非必填
				if (ruleForm.sbi_business_expand_type == '1') {
					if (ruleForm.sbi_capacity == '') {
						ElMessage.error("请输入报装电源容量");
						return false;
					}
				}
				loading.value = true;
				let params = {
					s_id: ruleForm.s_id,  // id
					sbi_osp_ids: ruleForm.sbi_osp_ids.join(","), // 监管平台互联互通账号ID
					sbi_country_code: ruleForm.sbi_country_code, // 国家代码
					sbi_area_code: ruleForm.sbi_area_code[ruleForm.sbi_area_code.length - 1], // 省市辖区编码
					sbi_area_code_countryside: ruleForm.sbi_area_code_countryside[ruleForm.sbi_area_code_countryside.length - 1], // 行政区域代码
					sbi_supporting_facilities: ruleForm.sbi_supporting_facilities.join(","),
					sbi_address: ruleForm.sbi_address, // 详细地址
					sbi_service_tel: ruleForm.sbi_service_tel,  // 服务电话
					sbi_station_classification: ruleForm.sbi_station_classification,  // 站点分类
					sbi_general_application_type: ruleForm.sbi_general_application_type, // 通用类型
					sbi_st_id: ruleForm.sbi_st_id, // 站点类型
					s_status: ruleForm.s_status, // 站点状态
					sbi_park_nums: ruleForm.sbi_park_nums, // 车位数量
					sbi_lng: ruleForm.sbi_lng, // 站点经度
					sbi_lat: ruleForm.sbi_lat, // 站点纬度
					sbi_site_guide: ruleForm.sbi_site_guide, // 站点引导
					sbi_sc_id: ruleForm.sbi_sc_id, // 站点建设场所
					sbi_busine_hours: ruleForm.sbi_busine_hours, // 营业时间
					sbi_round_the_clock: ruleForm.sbi_round_the_clock, // 是否24小时营业
					sbi_park_type: ruleForm.sbi_park_type, // 站点停车费类型
					sbi_park_fee: ruleForm.sbi_park_fee, // 停车费描述
					sbi_support_order: ruleForm.sbi_support_order,  // 是否支持预约
					sbi_remark: ruleForm.sbi_remark,  // 备注
					sbi_electricity_type: ruleForm.sbi_electricity_type,  // 电费类型
					sbi_business_expand_type: ruleForm.sbi_business_expand_type,  // 报装类型
					sbi_capacity: ruleForm.sbi_capacity,  // 报装电源容量
					sbi_rated_power: ruleForm.sbi_rated_power,  // 站点额定总功率
					sbi_period_fee: ruleForm.sbi_period_fee,  // 峰谷分时
					sbi_official_runtime: ruleForm.sbi_official_runtime,  // 正式投运时间
					sbi_station_orientation: ruleForm.sbi_station_orientation,  // 充换电方位
					sbi_station_area: ruleForm.sbi_station_area,  // 充换电站建筑面积
					sbi_have_person: ruleForm.sbi_have_person,  // 充换电站人工值守
					sbi_video_monitor: ruleForm.sbi_video_monitor,  // 视频监控配套情况
					sbi_printer_flag: ruleForm.sbi_printer_flag,  // 小票机
					sbi_barrier_flag: ruleForm.sbi_barrier_flag,  // 道闸
					sbi_parking_lock_flag: ruleForm.sbi_parking_lock_flag,  // 地锁
					sbi_equipment_owner_name: ruleForm.sbi_equipment_owner_name,  // 设备所属方名称
					sbi_supply_type: ruleForm.sbi_supply_type,  // 供电类型
					sbi_watt_hour_meter_no: ruleForm.sbi_watt_hour_meter_no,  // 表号
					sbi_forward_power: ruleForm.sbi_forward_power,  // 外电功率
					sbi_record_unique_no: ruleForm.sbi_record_unique_no,  // 全省唯一备案号
					sbi_match_car: ruleForm.sbi_match_car,  // 使用车型描述
					sbi_swap_match_cars: ruleForm.sbi_swap_match_cars,  // 服务车型描述
					sbi_resident_no: ruleForm.sbi_resident_no,  // 供电局用户编号
					sbi_station_tel: ruleForm.sbi_station_tel,  // 站点电话
					sbi_payment: ruleForm.sbi_payment,  // 支付方式
				};
				SystemManage.editSupervise(params).then(res => {
					loading.value = false;
					if (res.Code == 200) {
						ElMessage.success('编辑成功');
						ruleFormRef.value.resetFields();
						refWdialog.value.close()
						props.tableLoad();
					} else {
						ElMessage.error(res.Message);
					}
				}).catch((err) => {
					loading.value = false;
				});
			} else {
				loading.value = false
			}
		})
	}
	defineExpose({
		openDialog
	})
</script>

<style lang="scss" scoped>
	.stand-title {
		font-size: 16px;
		font-weight: bold;
		margin: 10px 0;
	}

	.supervise-content {
		overflow-y: auto;
		max-height: 600px;
		overflow-x: clip;
	}

	.basic-title {
		color: var(--theme-color);
		padding-bottom: 10px;
		display: flex;
	}

	.circle {
		z-index: 999;
		height: 20px;
		width: 20px;
		border-radius: 50%;
		background-color: var(--theme-color);
		color: #fff;
		text-align: center;
		line-height: 20px;
		margin-left: 5px;
		position: absolute;
		left: 60px;
		top: 11px;
		cursor: pointer;
	}

	.el-input-group__append {
		color: #000;
		border: none;
	}
</style>